import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import Select from "react-select";
import moment from "moment";
import SunEditor from "suneditor-react";
import { ModalConfirm } from "tamtam-components";
import { arrayMoveImmutable } from "array-move";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

import styles from "./Privacy.module.scss";
import _ from "../../i18n";
import {
  API_DATE_FORMAT,
  APP_ENV,
  SELECT_STYLES,
  TTP_API_URL,
} from "../../config";
import Switch from "../common/Switch/Switch";
import Tabs from "../common/Tabs";
import Loader from "../common/PageLoader";
import { default as DotLoader } from "../common/Loader";
import SortableRubricList from "./SortableRubricList";
import Button from "../common/Button";
import { convertDateToUTC } from "../../utils";
import RubricCard from "./RubricCard";

import {
  getPrivacyRubrics,
  removePrivacyRubric,
  savePrivacyRubricsOrder,
  saveVersion,
  updateVersionStatus,
  uploadTmpMedia,
} from "../../api";

const statusOptions = [
  { value: "DRAFT", label: _("draft") },
  { value: "PUBLISHED", label: _("published") },
];

const languages = [
  { value: "fr", label: _("french") },
  { value: "en", label: _("english") },
  { value: "nl", label: _("dutch") },
];

const PrivacyRubrics = () => {
  const showBackNav = useSelector((state) => state.params.backNav);
  const lng = useSelector((state) => state.params.language);
  const [title, setTitle] = useState({ en: "", fr: "", nl: "" });
  const [introductionFr, setIntroductionFr] = useState("");
  const [introductionEn, setIntroductionEn] = useState("");
  const [introductionNl, setIntroductionNl] = useState("");
  const [selectedRubric, setSelectedRubric] = useState(null);
  const [selectedVersion, setSelectedVersion] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [versionLanguage, setVersionLanguage] = useState(lng);
  const [rubrics, setRubrics] = useState([]);
  const [sortableRubrics, setSortableRubrics] = useState([]);
  const [tmpVersion, setTmpVersion] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [newRubric, setNewRubric] = useState(false);
  const [showSaveOrder, setShowSaveOrder] = useState(false);
  const [savingOrder, setSavingOrder] = useState(false);
  const [status, setStatus] = useState(statusOptions[0]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [searchParams] = useSearchParams();
  const rubParam = searchParams.get("rub");

  const titleAttr = `title${
    versionLanguage.charAt(0).toUpperCase() + versionLanguage.slice(1)
  }`;

  const introductionAttr = `introduction${
    versionLanguage.charAt(0).toUpperCase() + versionLanguage.slice(1)
  }`;

  const handlePaste = (event, cleanData, maxCharCount, core) => {
    let content = cleanData.replace(/\s+style="[^"]*"/gi, "");
    return content.replace(/(<\/?h)([0-1])/gi, "$12");
  };

  const auth = useSelector((state) => state.auth);

  const { token, navCommunity, user } = auth;

  let { isLoading, isFetching } = useQuery(
    ["getPrivacyRubrics", token, navCommunity],
    async () => {
      if (token) {
        try {
          const response = await getPrivacyRubrics({
            token,
            communityId: showBackNav && navCommunity ? navCommunity.id : 9,
          });
          if (response.data.data) {
            let tempRubrics = response.data.data;
            tempRubrics = tempRubrics.map((el) => {
              const lastVer = el.versions[0];
              let parser = new DOMParser();
              const docFr = parser.parseFromString(
                lastVer.introductionFr,
                "text/html"
              );
              const docEn = parser.parseFromString(
                lastVer.introductionEn,
                "text/html"
              );
              const docNl = parser.parseFromString(
                lastVer.introductionNl,
                "text/html"
              );

              const titlesFr = [];
              const titlesEn = [];
              const titlesNl = [];

              [...docFr.getElementsByTagName("h2")].forEach((el, i) => {
                el.setAttribute("id", `ch_fr_${i}`);
                titlesFr.push({ id: `ch_fr_${i}`, title: el.innerText });
              });
              [...docEn.getElementsByTagName("h2")].forEach((el, i) => {
                el.setAttribute("id", `ch_en_${i}`);
                titlesEn.push({ id: `ch_en_${i}`, title: el.innerText });
              });
              [...docNl.getElementsByTagName("h2")].forEach((el, i) => {
                el.setAttribute("id", `ch_nl_${i}`);
                titlesNl.push({ id: `ch_nl_${i}`, title: el.innerText });
              });

              return {
                ...el,
                titlesFr,
                titlesEn,
                titlesNl,
                versions: el.versions.map((ver) => {
                  if (ver.id === lastVer.id) {
                    return {
                      ...ver,
                      introductionFr: docFr.body.innerHTML,
                      introductionEn: docEn.body.innerHTML,
                      introductionNl: docNl.body.innerHTML,
                    };
                  }
                  return ver;
                }),
              };
            });
            if (!user || (user && !user.isUaAdmin)) {
              setSortableRubrics(
                tempRubrics
                  .filter((rub) => {
                    if (
                      rub.showInFooter &&
                      rub.versions[0].status === "PUBLISHED"
                    ) {
                      return rub;
                    } else return null;
                  })
                  .filter(Boolean)
              );
              setRubrics(
                tempRubrics
                  .filter((rub) => {
                    if (
                      rub.showInFooter &&
                      rub.versions[0].status === "PUBLISHED"
                    ) {
                      return rub;
                    } else return null;
                  })
                  .filter(Boolean)
              );
              setSelectedRubric(
                tempRubrics
                  .filter((rub) => {
                    if (
                      rub.showInFooter &&
                      rub.versions[0].status === "PUBLISHED"
                    ) {
                      return rub;
                    } else return null;
                  })
                  .filter(Boolean)[0]
              );
            } else {
              setSortableRubrics(tempRubrics);
              setRubrics(tempRubrics);
              setSelectedRubric(tempRubrics[0]);
            }
          }
        } catch (error) {
          setSortableRubrics([]);
          setRubrics([]);
          setSelectedRubric(null);
        }
      }
    }
  );

  useEffect(() => {
    if (selectedRubric) {
      setSelectedVersion(selectedRubric.versions[0]);
      setShowForm(false);
      setTmpVersion(null);
      setNewRubric(false);
      resetForm();
    }
  }, [selectedRubric]);

  useEffect(() => {
    if (rubrics.length > 0 && rubParam && user && user.isUaAdmin) {
      setSelectedRubric(
        rubrics.filter((el) => el.id.toString() === rubParam)[0]
      );
    } else if (rubrics.length > 0 && rubParam) {
      setSelectedRubric(
        rubrics
          .filter(
            (item) =>
              item.showInFooter && item.versions[0].status === "PUBLISHED"
          )
          .filter((el) => el.id.toString() === rubParam)[0]
      );
    }
  }, [rubParam, rubrics]);

  const resetForm = () => {
    setTitle({ en: "", fr: "", nl: "" });
    setIntroductionFr("");
    setIntroductionEn("");
    setIntroductionNl("");
    setStatus(statusOptions[0]);
    setShowForm(false);
    setTmpVersion(null);
    setNewRubric(false);
  };

  const newVersion = () => {
    if (selectedRubric) {
      let v = parseFloat(selectedRubric.versions[0].name);
      v += 0.1;
      return v.toFixed(1);
    }
  };
  const validate = () => {
    let errors = [];

    if (title[lng].trim().length === 0) {
      errors.push(_("validate_title"));
    }

    return errors;
  };

  const getChapterTitles = (lastVer) => {
    let parser = new DOMParser();
    const docFr = parser.parseFromString(lastVer.introductionFr, "text/html");
    const docEn = parser.parseFromString(lastVer.introductionEn, "text/html");
    const docNl = parser.parseFromString(lastVer.introductionNl, "text/html");

    const titlesFr = [];
    const titlesEn = [];
    const titlesNl = [];

    [...docFr.getElementsByTagName("h2")].forEach((el, i) => {
      el.setAttribute("id", `ch_fr_${i}`);
      titlesFr.push({ id: `ch_fr_${i}`, title: el.innerText });
    });
    [...docEn.getElementsByTagName("h2")].forEach((el, i) => {
      el.setAttribute("id", `ch_en_${i}`);
      titlesEn.push({ id: `ch_en_${i}`, title: el.innerText });
    });
    [...docNl.getElementsByTagName("h2")].forEach((el, i) => {
      el.setAttribute("id", `ch_nl_${i}`);
      titlesNl.push({ id: `ch_nl_${i}`, title: el.innerText });
    });

    return {
      titlesFr,
      titlesEn,
      titlesNl,
      version: {
        ...lastVer,
        introductionFr: docFr.body.innerHTML,
        introductionEn: docEn.body.innerHTML,
        introductionNl: docNl.body.innerHTML,
      },
    };
  };

  const handleSave = async () => {
    let errors = validate();

    if (errors && errors.length > 0) {
      let ErrorsContainer = ({ errors }) => (
        <div>
          <span>{_("errors") + " :"}</span>
          <ul>
            {errors.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        </div>
      );
      toast.error(<ErrorsContainer errors={errors} />);
      return;
    }

    const data = {
      name: tmpVersion ? tmpVersion.name : selectedVersion.name,
      title,
      introduction: {
        en: introductionEn,
        fr: introductionFr,
        nl: introductionNl,
      },
      status: status.value,
      publishedAt:
        status.value === "PUBLISHED"
          ? convertDateToUTC(new Date(), API_DATE_FORMAT, API_DATE_FORMAT)
          : "",
    };

    if (selectedVersion && !tmpVersion) {
      data.id = selectedVersion.id;
    }

    setIsSaving(true);
    try {
      const res = await saveVersion({
        token,
        rubricId: newRubric ? null : selectedRubric.id,
        communityId: navCommunity ? navCommunity.id : 9,
        data,
      });
      if (newRubric) {
        if (res.resRub.data.data && res.resVer.data.data) {
          setSortableRubrics([
            {
              ...res.resRub.data.data,
              titlesFr: getChapterTitles(res.resVer.data.data).titlesFr,
              titlesEn: getChapterTitles(res.resVer.data.data).titlesEn,
              titlesNl: getChapterTitles(res.resVer.data.data).titlesNl,
              showInFooter:
                res.resRub.data.data.showInFooter === 1 ? true : false,
              versions: [getChapterTitles(res.resVer.data.data).version],
            },
            ...sortableRubrics,
          ]);
          setRubrics([
            {
              ...res.resRub.data.data,
              titlesFr: getChapterTitles(res.resVer.data.data).titlesFr,
              titlesEn: getChapterTitles(res.resVer.data.data).titlesEn,
              titlesNl: getChapterTitles(res.resVer.data.data).titlesNl,
              showInFooter:
                res.resRub.data.data.showInFooter === 1 ? true : false,
              versions: [getChapterTitles(res.resVer.data.data).version],
            },
            ...sortableRubrics,
          ]);

          setSelectedRubric({
            ...res.resRub.data.data,
            titlesFr: getChapterTitles(res.resVer.data.data).titlesFr,
            titlesEn: getChapterTitles(res.resVer.data.data).titlesEn,
            titlesNl: getChapterTitles(res.resVer.data.data).titlesNl,
            versions: [getChapterTitles(res.resVer.data.data).version],
          });

          setSelectedVersion(getChapterTitles(res.resVer.data.data).version);
        }
      } else {
        if (res.data.data && selectedVersion && !tmpVersion) {
          setSortableRubrics(
            sortableRubrics.map((rub) => {
              if (rub.id === selectedRubric.id) {
                return {
                  ...rub,
                  titlesFr: getChapterTitles(res.data.data).titlesFr,
                  titlesEn: getChapterTitles(res.data.data).titlesEn,
                  titlesNl: getChapterTitles(res.data.data).titlesNl,
                  versions: rub.versions.map((ver) => {
                    if (ver.id === res.data.data.id) {
                      return {
                        ...getChapterTitles(res.data.data).version,
                      };
                    }
                    return ver;
                  }),
                };
              }
              return rub;
            })
          );
          setRubrics(
            sortableRubrics.map((rub) => {
              if (rub.id === selectedRubric.id) {
                return {
                  ...rub,
                  titlesFr: getChapterTitles(res.data.data).titlesFr,
                  titlesEn: getChapterTitles(res.data.data).titlesEn,
                  titlesNl: getChapterTitles(res.data.data).titlesNl,
                  versions: rub.versions.map((ver) => {
                    if (ver.id === res.data.data.id) {
                      return {
                        ...getChapterTitles(res.data.data).version,
                      };
                    }
                    return ver;
                  }),
                };
              }
              return rub;
            })
          );

          setSelectedRubric({
            ...selectedRubric,
            titlesFr: getChapterTitles(res.data.data).titlesFr,
            titlesEn: getChapterTitles(res.data.data).titlesEn,
            titlesNl: getChapterTitles(res.data.data).titlesNl,
            versions: selectedRubric.versions.map((ver) => {
              if (ver.id === res.data.data.id) {
                return {
                  ...getChapterTitles(res.data.data).version,
                };
              }
              return ver;
            }),
          });
        } else if (res.data.data) {
          setSortableRubrics(
            sortableRubrics.map((rub) => {
              if (rub.id === selectedRubric.id) {
                return {
                  ...rub,
                  titlesFr: getChapterTitles(res.data.data).titlesFr,
                  titlesEn: getChapterTitles(res.data.data).titlesEn,
                  titlesNl: getChapterTitles(res.data.data).titlesNl,
                  versions: [
                    getChapterTitles(res.data.data).version,
                    ...rub.versions,
                  ],
                };
              }
              return rub;
            })
          );
          setRubrics(
            sortableRubrics.map((rub) => {
              if (rub.id === selectedRubric.id) {
                return {
                  ...rub,
                  titlesFr: getChapterTitles(res.data.data).titlesFr,
                  titlesEn: getChapterTitles(res.data.data).titlesEn,
                  titlesNl: getChapterTitles(res.data.data).titlesNl,
                  versions: [
                    getChapterTitles(res.data.data).version,
                    ...rub.versions,
                  ],
                };
              }
              return rub;
            })
          );

          setSelectedRubric({
            ...selectedRubric,
            titlesFr: getChapterTitles(res.data.data).titlesFr,
            titlesEn: getChapterTitles(res.data.data).titlesEn,
            titlesNl: getChapterTitles(res.data.data).titlesNl,
            versions: [
              getChapterTitles(res.data.data).version,
              ...selectedRubric.versions,
            ],
          });

          setSelectedVersion(getChapterTitles(res.data.data).version);
        }
      }

      setIsSaving(false);
      setNewRubric(false);
      resetForm();
    } catch (error) {
      toast.error(_("saving_error"));
      setIsSaving(false);
      setNewRubric(false);
      resetForm();
    }
  };

  const changeVersionStatus = async () => {
    const data = {
      id: selectedVersion.id,
      status: selectedVersion.status === "DRAFT" ? "PUBLISHED" : "DRAFT",
      publishedAt:
        status.value === "DRAFT"
          ? convertDateToUTC(new Date(), API_DATE_FORMAT, API_DATE_FORMAT)
          : "",
    };
    setIsSaving(true);

    try {
      const res = await updateVersionStatus({
        token,
        data,
      });
      if (res.data.data) {
        setSortableRubrics(
          sortableRubrics.map((rub) => {
            if (rub.id === selectedRubric.id) {
              return {
                ...rub,
                versions: rub.versions.map((ver) => {
                  if (ver.id === res.data.data.id) {
                    return {
                      ...ver,
                      status: res.data.data.status,
                      publishedAt: res.data.data.publishedAt,
                    };
                  }
                  return ver;
                }),
              };
            }
            return rub;
          })
        );

        setRubrics(
          sortableRubrics.map((rub) => {
            if (rub.id === selectedRubric.id) {
              return {
                ...rub,
                versions: rub.versions.map((ver) => {
                  if (ver.id === res.data.data.id) {
                    return {
                      ...ver,
                      status: res.data.data.status,
                      publishedAt: res.data.data.publishedAt,
                    };
                  }
                  return ver;
                }),
              };
            }
            return rub;
          })
        );

        setSelectedRubric({
          ...selectedRubric,
          versions: selectedRubric.versions.map((ver) => {
            if (ver.id === res.data.data.id) {
              return {
                ...ver,
                status: res.data.data.status,
                publishedAt: res.data.data.publishedAt,
              };
            }
            return ver;
          }),
        });
      }
      setIsSaving(false);
    } catch (error) {
      toast.error(_("saving_error"));
      setIsSaving(false);
    }
  };

  const sortRubrics = ({ oldIndex, newIndex }) => {
    setShowSaveOrder(true);
    setSortableRubrics(arrayMoveImmutable(sortableRubrics, oldIndex, newIndex));
  };

  const handleSaveOrder = async () => {
    try {
      setSavingOrder(true);
      await savePrivacyRubricsOrder(token, sortableRubrics);
      setRubrics(sortableRubrics);
      setSavingOrder(false);
      setShowSaveOrder(false);
    } catch (error) {
      setSavingOrder(false);
      setShowSaveOrder(false);
    }
  };

  const deleteRubric = async () => {
    setIsSaving(true);
    try {
      const res = await removePrivacyRubric({
        token,
        rubricId: selectedRubric.id,
      });
      setSortableRubrics(
        sortableRubrics.filter((el) => el.id !== res.data.deletedId)
      );
      setRubrics(rubrics.filter((el) => el.id !== res.data.deletedId));
      setSelectedRubric(
        sortableRubrics.filter((el) => el.id !== res.data.deletedId)[0]
      );
      setSelectedVersion(
        sortableRubrics.filter((el) => el.id !== res.data.deletedId)[0]
          .versions[0]
      );
      setIsSaving(false);
      setIsOpenDeleteModal(false);
    } catch (error) {
      console.error(error);
      toast.error(_("deleting_error"));
      setIsSaving(false);
    }
  };

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    uploadTmpMedia({ token: auth.token, data: files[0] }).then((resp) => {
      const url = resp.data.data.url;
      const startsWithHttp = url.lastIndexOf("http://", 0) === 0;
      const startsWithHttps = url.lastIndexOf("https://", 0) === 0;
      const isAbsolute = startsWithHttp || startsWithHttps;
      const imgUrl = isAbsolute ? url : `${TTP_API_URL}/${url}`;
      uploadHandler({
        result: [
          {
            url: imgUrl,
            name: files[0].name,
            size: files[0].size,
          },
        ],
      });
    });
  };

  const RubricForm = () => {
    return (
      <div className={styles.rubric_form}>
        {isSaving && <div className={styles.saving}></div>}
        <div className={styles.formBody}>
          <div className={styles.statusRow}>
            <div className={styles.statusSelect}>
              <label>{_("faq_status")}</label>
              <Select
                styles={SELECT_STYLES}
                options={statusOptions}
                isSearchable={false}
                value={status}
                onChange={(e) => setStatus(e)}
              />
            </div>
          </div>
          <Tabs theme="wtheme">
            {languages.map((lng) => (
              <div
                key={`langtab${lng.value}`}
                label={lng.label}
                icon={`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/${lng.value.toUpperCase()}.jpg`}
              >
                <div className={styles.langTab}>
                  <div className={styles.formRow}>
                    <label className={styles.configLabel}>
                      {_("rubric")} {lng.value}
                    </label>
                    <input
                      className={styles.formInput}
                      value={title[lng.value]}
                      onChange={(e) => {
                        setTitle({
                          ...title,
                          [lng.value]: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <label className={styles.configLabel}>
                    {_("description")} {lng.value}
                  </label>
                  <div className={`ttp-helpeditor ${styles.editorContainer} `}>
                    <SunEditor
                      setOptions={{
                        attributesWhitelist: {
                          // all: "style",
                          input: "checked",
                        },
                        stickyToolbar: false,
                        height: "auto",
                        minHeight: "200px",
                        showPathLabel: false,
                        resizingBar: false,
                        imageUrlInput: false,
                        imageWidth: "75%",
                        formats: [
                          "p",
                          "blockquote",
                          "h2",
                          "h3",
                          "h4",
                          "h5",
                          "h6",
                        ],
                        buttonList: [
                          [
                            "undo",
                            "redo",
                            "formatBlock",
                            "bold",
                            "underline",
                            "italic",
                            "strike",
                            "outdent",
                            "indent",
                            "align",
                            "list",
                            "table",
                            "link",
                            "image",
                            "video",
                            // "fullScreen",
                            "showBlocks",
                          ],
                        ],
                      }}
                      setContents={
                        lng.value === "fr"
                          ? introductionFr
                          : lng.value === "en"
                          ? introductionEn
                          : introductionNl
                      }
                      onChange={(c) => {
                        lng.value === "fr"
                          ? setIntroductionFr(c)
                          : lng.value === "en"
                          ? setIntroductionEn(c)
                          : setIntroductionNl(c);
                      }}
                      onPaste={handlePaste}
                      onImageUploadBefore={handleImageUploadBefore}
                    />
                  </div>
                </div>
              </div>
            ))}
          </Tabs>
        </div>
        <div className={styles.formFooter}>
          <Button
            variant="default"
            onClick={() => {
              resetForm();
              setSelectedVersion(selectedRubric.versions[0]);
            }}
          >
            {_("cancel")}
          </Button>
          <Button variant="primary" onClick={() => handleSave()}>
            {_("save")}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isLoading || isFetching ? (
        <div style={{ marginTop: "4rem" }}>
          <Loader />
        </div>
      ) : selectedRubric && sortableRubrics.length > 0 ? (
        <div className={classNames(styles.pageContent, "grid-x")}>
          <div className={classNames("cell medium-12 large-4")}>
            <div className={styles.left}>
              <h1> {_("privacy_rubrics")} </h1>
              {user && user.isUaAdmin ? (
                <>
                  <SortableRubricList
                    onSortEnd={(e) => sortRubrics(e)}
                    sortableRubrics={sortableRubrics}
                    setSortableRubrics={setSortableRubrics}
                    language={lng}
                    setSelectedRubric={setSelectedRubric}
                    selectedRubric={selectedRubric}
                    distance={1}
                  />
                  {showSaveOrder ? (
                    <div className={styles.saveOrder}>
                      <Button
                        variant="default"
                        onClick={() => {
                          setShowSaveOrder(false);
                          setSortableRubrics(rubrics);
                        }}
                      >
                        {_("cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        disabled={savingOrder}
                        onClick={() => handleSaveOrder()}
                        style={{
                          paddingTop: savingOrder ? "15px" : "default",
                          paddingBottom: savingOrder ? "15px" : "default",
                        }}
                      >
                        {savingOrder ? (
                          <DotLoader
                            style={{
                              height: "10px",
                            }}
                            color={"#fff"}
                          />
                        ) : (
                          _("save_order")
                        )}
                      </Button>
                    </div>
                  ) : (
                    <div className={styles.addRubric}>
                      <button
                        onClick={() => {
                          setNewRubric(true);
                          setTmpVersion({ name: "1.0" });
                          setShowForm(true);
                          setSelectedVersion(null);
                        }}
                        disabled={showForm}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.2402 6.0242H7.97672V0.760665C7.97672 0.520889 7.65113 0.000976562 6.99997 0.000976562C6.34881 0.000976562 6.02322 0.520917 6.02322 0.760665V6.02423H0.759688C0.519941 6.0242 0 6.34979 0 7.00092C0 7.65205 0.519941 7.97767 0.759688 7.97767H6.02325V13.2412C6.02325 13.481 6.34881 14.0009 7 14.0009C7.65119 14.0009 7.97675 13.481 7.97675 13.2412V7.97767H13.2403C13.48 7.97767 14 7.65211 14 7.00092C14 6.34974 13.48 6.0242 13.2402 6.0242Z"
                            fill="#18A0FB"
                          />
                        </svg>
                      </button>
                      <label>{_("add_rubric")}</label>
                    </div>
                  )}
                </>
              ) : (
                <div>
                  {rubrics.length > 0 &&
                  rubrics.filter(
                    (el) =>
                      el.showInFooter && el.versions[0].status === "PUBLISHED"
                  ).length > 0 ? (
                    rubrics.map((item) => {
                      if (!item.showInFooter) return null;
                      if (item.versions[0].status === "DRAFT") return null;
                      return (
                        <>
                          <RubricCard
                            item={item}
                            language={lng}
                            selectedRubric={selectedRubric}
                            setSelectedRubric={setSelectedRubric}
                          />
                        </>
                      );
                    })
                  ) : (
                    <p>{_("No rubrics available")}</p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={classNames(styles.right, "cell medium-12 large-8")}>
            {user && user.isUaAdmin ? (
              <div className={styles.top}>
                <ul className={styles.versions}>
                  {!showForm && (
                    <li
                      onClick={() => {
                        setShowForm(true);
                        setTmpVersion({ name: newVersion() });
                        setSelectedVersion(null);
                        if (selectedRubric) {
                          setStatus(
                            statusOptions.filter(
                              (el) =>
                                el.value === selectedRubric.versions[0].status
                            )[0]
                          );
                          setIntroductionFr(
                            selectedRubric.versions[0].introductionFr
                          );
                          setIntroductionEn(
                            selectedRubric.versions[0].introductionEn
                          );
                          setIntroductionNl(
                            selectedRubric.versions[0].introductionNl
                          );
                          setTitle({
                            fr: selectedRubric.versions[0].titleFr,
                            en: selectedRubric.versions[0].titleEn,
                            nl: selectedRubric.versions[0].titleNl,
                          });
                        }
                      }}
                    >
                      <svg
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_50815_37461)">
                          <path
                            d="M11.3488 5.16374H6.83719V0.652138C6.83719 0.446616 6.55811 0.000976562 5.99998 0.000976562C5.44184 0.000976562 5.16276 0.44664 5.16276 0.652138V5.16376H0.651161C0.445663 5.16374 0 5.44282 0 6.00093C0 6.55904 0.445663 6.83814 0.651161 6.83814H5.16279V11.3498C5.16279 11.5552 5.44184 12.0009 6 12.0009C6.55816 12.0009 6.83721 11.5552 6.83721 11.3498V6.83814H11.3488C11.5543 6.83814 12 6.55909 12 6.00093C12 5.44277 11.5543 5.16374 11.3488 5.16374Z"
                            fill="#6D7F92"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_50815_37461">
                            <rect width="12" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </li>
                  )}
                  {tmpVersion && (
                    <li className={styles.active_label}>
                      Version {tmpVersion.name}
                    </li>
                  )}
                  {!newRubric &&
                    selectedRubric &&
                    selectedRubric.versions.map((version) => (
                      <li
                        className={
                          !tmpVersion &&
                          selectedVersion &&
                          version.id === selectedVersion.id
                            ? styles.active_label
                            : ""
                        }
                        onClick={() => {
                          if (!tmpVersion) setSelectedVersion(version);
                        }}
                      >
                        Version {version.name}
                      </li>
                    ))}
                </ul>
                {!tmpVersion &&
                  selectedRubric &&
                  selectedVersion.id === selectedRubric.versions[0].id && (
                    <div className={styles.actions}>
                      <div
                        className={styles.edit}
                        onClick={() => {
                          setTmpVersion(null);
                          setTitle({
                            fr: selectedVersion.titleFr,
                            en: selectedVersion.titleEn,
                            nl: selectedVersion.titleNl,
                          });
                          setIntroductionEn(selectedVersion.introductionEn);
                          setIntroductionFr(selectedVersion.introductionFr);
                          setIntroductionNl(selectedVersion.introductionNl);
                          setStatus(
                            statusOptions.filter(
                              (el) => el.value === selectedVersion.status
                            )[0]
                          );
                          setShowForm(true);
                        }}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_50853_38838)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.082 1.84977C10.942 1.84977 10.8033 1.87736 10.6739 1.93096C10.5445 1.98456 10.4269 2.06313 10.3279 2.16217L2.5624 9.92763L1.99676 12.0016L4.07077 11.436L11.8362 3.67054C11.9353 3.5715 12.0138 3.45392 12.0674 3.32452C12.121 3.19512 12.1486 3.05642 12.1486 2.91635C12.1486 2.77629 12.121 2.63759 12.0674 2.50819C12.0138 2.37879 11.9353 2.26121 11.8362 2.16217C11.7372 2.06313 11.6196 1.98456 11.4902 1.93096C11.3608 1.87736 11.2221 1.84977 11.082 1.84977ZM10.2274 0.853101C10.4984 0.74087 10.7888 0.683105 11.082 0.683105C11.3753 0.683105 11.6657 0.74087 11.9367 0.853101C12.2076 0.965333 12.4538 1.12983 12.6612 1.33721C12.8686 1.54458 13.0331 1.79078 13.1453 2.06173C13.2575 2.33268 13.3153 2.62308 13.3153 2.91635C13.3153 3.20963 13.2575 3.50003 13.1453 3.77098C13.0331 4.04193 12.8686 4.28812 12.6612 4.4955L4.78619 12.3705C4.71441 12.4423 4.62514 12.4941 4.5272 12.5208L1.31886 13.3958C1.11691 13.4509 0.90092 13.3935 0.752899 13.2455C0.604878 13.0975 0.54752 12.8815 0.602599 12.6795L1.4776 9.4712C1.50431 9.37326 1.55612 9.28399 1.6279 9.21221L9.5029 1.33721C9.71028 1.12983 9.95647 0.965333 10.2274 0.853101Z"
                              fill="#6D7F92"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_50853_38838">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div
                        className={styles.delete}
                        onClick={() => setIsOpenDeleteModal(true)}
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_50853_38831)">
                            <path
                              d="M12.8363 2.0148H9.92352V1.53989C9.92352 0.69079 9.23273 0 8.38363 0H5.61472C4.76562 0 4.07484 0.69079 4.07484 1.53989V2.0148H1.16201C0.946135 2.0148 0.773438 2.1875 0.773438 2.40337C0.773438 2.61924 0.946135 2.79194 1.16201 2.79194H1.86431V11.9219C1.86431 13.0674 2.79687 14 3.94243 14H10.0559C11.2015 14 12.134 13.0674 12.134 11.9219V2.79194H12.8363C13.0522 2.79194 13.2249 2.61924 13.2249 2.40337C13.2249 2.1875 13.0522 2.0148 12.8363 2.0148ZM4.85197 1.53989C4.85197 1.11965 5.19449 0.777138 5.61472 0.777138H8.38363C8.80386 0.777138 9.14638 1.11965 9.14638 1.53989V2.0148H4.85197V1.53989ZM11.3569 11.9219C11.3569 12.6386 10.7726 13.2229 10.0559 13.2229H3.94243C3.22574 13.2229 2.64145 12.6386 2.64145 11.9219V2.79194H11.3598V11.9219H11.3569Z"
                              fill="#F9626D"
                            />
                            <path
                              d="M6.99794 11.8298C7.21381 11.8298 7.3865 11.6571 7.3865 11.4412V4.57363C7.3865 4.35776 7.21381 4.18506 6.99794 4.18506C6.78207 4.18506 6.60938 4.35776 6.60938 4.57363V11.4383C6.60938 11.6542 6.78207 11.8298 6.99794 11.8298Z"
                              fill="#F9626D"
                            />
                            <path
                              d="M4.46279 11.4007C4.67866 11.4007 4.85136 11.228 4.85136 11.0122V4.99941C4.85136 4.78354 4.67866 4.61084 4.46279 4.61084C4.24692 4.61084 4.07422 4.78354 4.07422 4.99941V11.0122C4.07422 11.228 4.24979 11.4007 4.46279 11.4007Z"
                              fill="#F9626D"
                            />
                            <path
                              d="M9.5331 11.4007C9.74897 11.4007 9.92167 11.228 9.92167 11.0122V4.99941C9.92167 4.78354 9.74897 4.61084 9.5331 4.61084C9.31723 4.61084 9.14453 4.78354 9.14453 4.99941V11.0122C9.14453 11.228 9.31723 11.4007 9.5331 11.4007Z"
                              fill="#F9626D"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_50853_38831">
                              <rect width="14" height="14" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                  )}
              </div>
            ) : (
              <div className={styles.fakeTop}></div>
            )}
            {showForm ? (
              <>{RubricForm()}</>
            ) : (
              <div
                className={classNames(
                  styles.rubric_display
                  // user && user.isUaAdmin ? "" : styles.hideOnIpad
                )}
              >
                {isSaving && <div className={styles.saving}></div>}
                <div className={styles.languageTabs}>
                  {selectedVersion && (
                    <ul>
                      {selectedVersion.titleFr.length > 0 && (
                        <li onClick={() => setVersionLanguage("fr")}>
                          <div
                            className={styles.flag}
                            style={{
                              backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/FR.jpg`})`,
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor:
                                versionLanguage === "fr" && "#18A0FB",
                            }}
                            className={styles.border}
                          ></div>
                        </li>
                      )}
                      {selectedVersion.titleEn.length > 0 && (
                        <li onClick={() => setVersionLanguage("en")}>
                          <div
                            className={styles.flag}
                            style={{
                              backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/EN.jpg`})`,
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor:
                                versionLanguage === "en" && "#18A0FB",
                            }}
                            className={styles.border}
                          ></div>
                        </li>
                      )}
                      {selectedVersion.titleNl.length > 0 && (
                        <li onClick={() => setVersionLanguage("nl")}>
                          <div
                            className={styles.flag}
                            style={{
                              backgroundImage: `url(${`https://tamtam.s3-eu-west-1.amazonaws.com/cdn/widget/${APP_ENV}/img/flags/NL.jpg`})`,
                            }}
                          ></div>
                          <div
                            style={{
                              backgroundColor:
                                versionLanguage === "nl" && "#18A0FB",
                            }}
                            className={styles.border}
                          ></div>
                        </li>
                      )}
                    </ul>
                  )}
                  {user &&
                    user.isUaAdmin &&
                    selectedVersion.id === selectedRubric.versions[0].id && (
                      <div className={styles.isActive}>
                        <label
                          className={classNames(
                            styles.label,
                            selectedVersion.status === "PUBLISHED"
                              ? styles.active_label
                              : ""
                          )}
                        >
                          {selectedVersion.status === "PUBLISHED"
                            ? _("activated")
                            : _("deactivated")}
                        </label>
                        <Switch
                          isChecked={selectedVersion.status === "PUBLISHED"}
                          onChange={(e) => {
                            changeVersionStatus();
                          }}
                        />
                      </div>
                    )}
                </div>
                <p className={styles.title}>{selectedVersion[titleAttr]}</p>
                <div className={styles.details}>
                  {user && user.isUaAdmin && (
                    <>
                      <span className={styles.publishedAt}>
                        {selectedVersion.status === "DRAFT"
                          ? _("draft")
                          : _("published_at") +
                            " " +
                            moment(selectedVersion.publishedAt, API_DATE_FORMAT)
                              .locale(versionLanguage)
                              .format("DD MMM YYYY")}
                      </span>
                      <label className={styles.dot}></label>
                    </>
                  )}

                  <span className={styles.version}>
                    version {selectedVersion.name}
                  </span>
                </div>
                <div
                  className="sun-editor sun-editor-editable"
                  dangerouslySetInnerHTML={{
                    __html: selectedVersion[introductionAttr],
                  }}
                ></div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={classNames(styles.pageContent, "grid-x")}>
          <div className={classNames("cell medium-12 large-4")}>
            <div className={styles.left}>
              <h1> {_("privacy_rubrics")} </h1>
              {user && user.isUaAdmin ? (
                <div className={styles.addRubric}>
                  <button
                    onClick={() => {
                      setNewRubric(true);
                      setTmpVersion({ name: "1.0" });
                      setShowForm(true);
                      setSelectedVersion(null);
                    }}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.2402 6.0242H7.97672V0.760665C7.97672 0.520889 7.65113 0.000976562 6.99997 0.000976562C6.34881 0.000976562 6.02322 0.520917 6.02322 0.760665V6.02423H0.759688C0.519941 6.0242 0 6.34979 0 7.00092C0 7.65205 0.519941 7.97767 0.759688 7.97767H6.02325V13.2412C6.02325 13.481 6.34881 14.0009 7 14.0009C7.65119 14.0009 7.97675 13.481 7.97675 13.2412V7.97767H13.2403C13.48 7.97767 14 7.65211 14 7.00092C14 6.34974 13.48 6.0242 13.2402 6.0242Z"
                        fill="#18A0FB"
                      />
                    </svg>
                  </button>
                  <label>{_("add_rubric")}</label>
                </div>
              ) : (
                <p>{_("No rubrics available")}</p>
              )}
            </div>
          </div>
          <div className={classNames(styles.right, "cell medium-12 large-8")}>
            <div className={styles.fakeTop}></div>
            {showForm && <>{RubricForm()}</>}
          </div>
        </div>
      )}
      <ModalConfirm
        type="delete"
        isOpen={isOpenDeleteModal}
        inProcess={isSaving}
        onCancel={() => setIsOpenDeleteModal(false)}
        onConfirm={deleteRubric}
        title={_("modal_delete_title")}
        text={_("modal_delete_text")}
        labelNo={_("no")}
        labelYes={_("yes")}
      />
    </div>
  );
};

export default PrivacyRubrics;
