import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";

import _ from "../../i18n";
import styles from "./Privacy.module.scss";
import { ReactComponent as BlackLeftArrow } from "../Pages/assets/BlackLeftArrow.svg";
import PrivacyRubrics from "./PrivacyRubrics";
import Cookies from "./Cookies";
import { getPrivacyCookies, getTermsOfUse } from "../../api";
import Loader from "../common/PageLoader";
import classNames from "classnames";
import TermsOfUse from "./TermsOfUse";

const Privacy = () => {
  const lng = useSelector((state) => state.params.language);
  const navigate = useNavigate();
  const showBackNav = useSelector((state) => state.params.backNav);
  const currentApp = useSelector((state) => state.params.currentApp);
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const { token, navCommunity, user } = auth;

  let { isLoading, data } = useQuery(
    ["getPrivacyCookies", token, navCommunity],
    async () => {
      if (token) {
        try {
          const response = await getPrivacyCookies({
            token,
            communityId: showBackNav && navCommunity ? navCommunity.id : 9,
          });
          return response.data.data;
        } catch (error) {}
      }
    }
  );

  let { isLoading: loadingTerms, data: termsData } = useQuery(
    ["getTermsOfUse", token, navCommunity],
    async () => {
      if (token) {
        try {
          const response = await getTermsOfUse({
            token,
            communityId: showBackNav && navCommunity ? navCommunity.id : 9,
          });
          if (!user || (user && !user.isUaAdmin)) {
            if (
              response.data.data[0].versions.filter(
                (el) => el.name === currentApp
              ).length > 0
            ) {
              return {
                ...response.data.data[0],
                versions: response.data.data[0].versions.filter(
                  (el) => el.name === currentApp
                ),
              };
            } else return null;
          }
          if (
            response.data.data[0].versions.filter(
              (el) => el.name === currentApp
            ).length > 0
          ) {
            return {
              ...response.data.data[0],
              versions: [
                response.data.data[0].versions.filter(
                  (el) => el.name === currentApp
                )[0],
                ...response.data.data[0].versions.filter(
                  (el) => el.name !== currentApp
                ),
              ],
            };
          } else return response.data.data[0];
        } catch (error) {}
      }
    }
  );

  return (
    <div className={styles.privacyPage}>
      {showBackNav && (
        <div className={styles.backNav}>
          <button onClick={() => navigate(-1)}>
            <BlackLeftArrow />
            {_("back")}
          </button>
          <span>
            {location.pathname.includes("cookies")
              ? _("cookies")
              : location.pathname.includes("terms_of_use")
              ? _("terms_of_use")
              : _("privacy")}
          </span>
        </div>
      )}

      {isLoading || loadingTerms ? (
        <div style={{ marginTop: "4rem" }}>
          <Loader />
        </div>
      ) : (
        <>
          {((user && user.isUaAdmin) || data || termsData) && (
            <div
              className={classNames(
                styles.headerTab,
                !showBackNav ? styles.headerTab_marginTop : ""
              )}
            >
              <button
                className={`${styles.headerTab__button} ${
                  !location.pathname.includes("cookies") &&
                  !location.pathname.includes("terms_of_use") &&
                  styles.headerTab__button_active
                }`}
                onClick={() => navigate(`/${lng}/privacy`)}
              >
                {_("privacy")}
              </button>
              {((user && user.isUaAdmin) || data) && (
                <button
                  className={`${styles.headerTab__button} ${
                    location.pathname.includes("cookies") &&
                    styles.headerTab__button_active
                  }`}
                  onClick={() => navigate(`/${lng}/privacy/cookies`)}
                >
                  {_("cookies")}
                </button>
              )}
              {((user && user.isUaAdmin) || termsData) && (
                <button
                  className={`${styles.headerTab__button} ${
                    location.pathname.includes("terms_of_use") &&
                    styles.headerTab__button_active
                  }`}
                  onClick={() => navigate(`/${lng}/privacy/terms_of_use`)}
                >
                  {_("terms_of_use")}
                </button>
              )}
            </div>
          )}
          {location.pathname.includes("cookies") &&
          ((user && user.isUaAdmin) || data) ? (
            <Cookies
              data={data}
              isUaAdmin={user && user.isUaAdmin}
              lng={lng}
              token={token}
              navCommunity={navCommunity}
              user={user && user}
            />
          ) : location.pathname.includes("terms_of_use") &&
            ((user && user.isUaAdmin) || termsData) ? (
            <TermsOfUse
              data={termsData}
              isUaAdmin={user && user.isUaAdmin}
              lng={lng}
              token={token}
              navCommunity={navCommunity}
              user={user && user}
            />
          ) : (
            <PrivacyRubrics />
          )}
        </>
      )}
    </div>
  );
};

export default Privacy;
